<template>
  <div>
    <el-dialog
      v-model="visible"
      width="35%"
      append-to-body
      align-center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
    >
      <div class="quickContact-dialog-wrap">
        <img
          class="logo"
          src="../../assets/image/deConsultant/quick-logo.png"
          alt=""
        />
        <div class="card-code-wrap">
          <img src="../../assets/image/deConsultant/quick-code.png" alt="" />
          <div class="right">
            <div class="tel">TEL:</div>
            <div class="phone">185-8399-2018</div>
          </div>
        </div>
        <div class="flex-wrap">
          <div class="title">业务咨询</div>
          <div class="content">请完善信息，让我们更好地为您服务</div>
        </div>

        <el-form
          :model="form"
          ref="formRef"
          label-position="top"
          :rules="rules"
        >
          <el-form-item
            label="企业名称"
            class="form-item companyName"
            prop="companyName"
          >
            <el-input v-model="form.companyName" />
          </el-form-item>

          <el-form-item label="您的姓名" class="form-item name" prop="name">
            <el-input v-model="form.name" />
          </el-form-item>

          <el-form-item label="您的电话" class="form-item phone" prop="phone">
            <el-input v-model="form.phone" />
          </el-form-item>

          <el-form-item label="您的微信" class="form-item wechat" prop="wechat">
            <el-input v-model="form.wechat" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="footer-btn-wrap">
          <el-button class="btn" @click="submitSave">提 交</el-button>

          <div class="btn-dec">
            提交即代表您已阅读并同意<a
              class="privacy"
              :href="`/#/privacyPolicy`"
              target="_blank"
              >《隐私条款》</a
            >
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { isPhone } from '/@/utils/validate'

export default {
  name: 'QuickContact',
  setup() {
    const visible = ref(false)
    const form = ref({})
    const formRef = ref(null)
    const validatePhone = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error('请输入正确的手机号或者固话号'))
      } else {
        callback()
      }
    }
    const rules = {
      name: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入您的姓名'
        }
      ],
      phone: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入您的电话'
        },
        { validator: validatePhone, trigger: 'blur' }
      ]
    }

    const open = () => {
      visible.value = true
    }

    const submitSave = async () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          await axios
            .post(
              'http://platform.rongzhiyingxiao.com/api/public/saveClue',
              form.value
            )
            .then(() => {
              close()
              ElMessage.success({
                message: '提交成功',
                type: 'success'
              })
            })
            .catch(() => {
              // 请求失败后的处理逻辑
              ElMessage.error('请求失败，错误信息')
            })
        }
      })
    }

    const close = () => {
      formRef.value.resetFields()
      visible.value = false
    }

    return {
      visible,
      open,
      form,
      submitSave,
      rules,
      formRef
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>