<template>
  <div class="header-wrap">
    <div class="logo-wrap">
      <img
        src="../../assets/image/home/nav-logo.png"
        style="width: 239px; height: 60px"
        alt=""
      />
    </div>
    <div class="nav-wrap">
      <router-link
        :to="item.url"
        v-for="(item, index) in headerWarp"
        :key="index"
      >
        <div
          class="nav-item"
          :class="item.title == $props.navActive ? 'active' : ''"
        >
          {{ item.title }}
          <template v-if="item.pullList?.length > 0">
            <div class="pull-down-wrap" :style="pullDownStyle">
              <div
                v-for="(pull, pullIndex) in item.pullList"
                :key="pullIndex"
                @click.stop="pullClick(pull)"
              >
                <router-link :to="pull.url">
                  <div
                    class="pull-item"
                    :class="
                      pull.title == $props.pullActive ? 'active-pull-item' : ''
                    "
                  >
                    {{ pull.title }}
                  </div>
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'FooterPage',
  props: {
    navActive: {
      type: String,
      default: ''
    },

    pullActive: {
      type: String,
      default: ''
    },

    pullDownStyle: {
      type: String,
      default: 'box-shadow: 0px 4px 29px 0px #ECECEC;'
    }
  },
  setup(_props, { emit }) {
    const headerWarp = ref([
      {
        title: '首页',
        url: '/',
        pullList: []
      },
      {
        title: '整合营销',
        url: '/integrated',
        pullList: [
          {
            id: 'localLife',
            title: '本地生活',
            url: '/integrated/secondLevel/localLife'
          },
          // {
          //   id: 'eCommerceLive',
          //   title: '电商直播服务',
          //   url: '/integrated/secondLevel/eCommerceLive'
          // },
          {
            id: 'performanceAd',
            title: '效果广告',
            url: '/integrated/secondLevel/performanceAd'
          },
          {
            id: 'brandComm',
            title: '品牌传播服务',
            url: '/integrated/secondLevel/brandComm'
          },
          {
            id: 'contentOp',
            title: '内容运营服务',
            url: '/integrated/secondLevel/contentOp'
          },
          {
            id: 'accompanyRunner',
            title: '陪跑伴教服务',
            url: '/integrated/secondLevel/accompanyRunner'
          }
        ]
      },
      {
        title: '智能工具',
        url: '/intelligentTool',
        pullList: [
          {
            id: 'customerService',
            title: '融智AI客服工具',
            url: '/intelligentTool'
          }
        ]
      },
      {
        title: '品牌孵化',
        url: '/brandIncubation',
        pullList: []
      },
      {
        title: '关于我们',
        url: '/about',
        pullList: []
      }
    ])

    const pullClick = (pull) => {
      // 传参给父组件
      emit('pullClick', pull)
    }

    return {
      headerWarp,
      pullClick
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>