<template>
  <div class="privacy-policy-cantainer" ref="homeRef">
    <!-- 关于我们二级页 -->
    <div class="header-bg">
      <HeaderWarp />
    </div>

    <div class="privacy-wrap">
      <div class="privacy-title">融智互动隐私政策</div>
      <div class="privacy-time">本版更新时间：2024 年【11】 月【25】 日</div>

      <div class="one-paragraph">
        <span class="text-weight">【前言】</span
        >成都融智互动科技有限公司（以下简称“融智互动”或“公司”）深知用户信息保护对用户的重要性，并会尽我们最大努力保护用户信息安全，我们将依法采取安全保护措施，保护用户的信息及隐私安全，并将恪守以下原则：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。
      </div>

      <div class="two-paragraph text-weight">
        【特别提示】在用户使用融智互动相关产品或服务时，我们将按本《隐私政策》收集、保存、使用、共享、披露及保护用户的信息。我们希望通过本《隐私政策》向用户介绍我们对用户信息的处理方式。因此，我们提请用户：在浏览本网站相关信息或享受相关服务时，仔细阅读并充分理解本《隐私政策》，在确认充分理解并同意后方使用相关产品和服务。在您勾选“已阅读并同意《注册服务协议》及《隐私政策》”并点击立即注册即视为您已充分理解并同意本《隐私政策》的全部内容，本《隐私政策》在您注册成功时同时生效。
      </div>

      <div class="three-paragraph">
        请您在使用融智互动相关产品和服务前仔细阅读并理解本隐私政策，尤其是字体加粗、下划线的内容，以便做出适当的选择。
      </div>

      <div class="headline-paragraph text-weight">
        本隐私政策将帮助用户了解以下内容：
      </div>

      <div class="headline-paragraph text-weight">
        一、我们收集的信息及相应的使用目的
      </div>

      <div class="headline-paragraph text-weight">二、信息收集的范围与方式</div>

      <div class="headline-paragraph text-weight">
        三、Cookie和同类技术的使用
      </div>

      <div class="headline-paragraph text-weight">四、用户信息的存储</div>

      <div class="headline-paragraph text-weight">五、用户信息的使用</div>

      <div class="headline-paragraph text-weight">
        六、用户信息的共享、转让与公开披露
      </div>

      <div class="headline-paragraph text-weight">七、用户信息的安全保护</div>

      <div class="headline-paragraph text-weight">八、用户信息的管理</div>

      <div class="headline-paragraph text-weight">九、未成年人使用条款</div>

      <div class="headline-paragraph text-weight">十、隐私政策的变更</div>

      <div class="headline-paragraph text-weight">十一、争议解决</div>

      <div class="headline-paragraph text-weight">十二、如何联系我们</div>

      <div class="headline-paragraph text-weight">十三、其他</div>

      <div class="headline-content-paragraph text-weight">
        一、我们收集的信息及相应的使用目的
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 23px">
          我们将遵循合法、正当、必要的原则，收集和使用您的个人信息。具体而言，我们出于为您提供相应的业务功能而收集并处理您的个人信息。相应功能中，如果您提供的是他人个人信息，请您确保已取得相关主体的授权，我们保留随时核查该等个人信息合法来源的权利。
        </div>

        <div class="main-body" style="margin-top: 21px">
          您需要向我们提供为实现基本业务功能所必需的信息为必要个人信息，若您拒绝提供这些必要个人信息，我们将无法向您提供该基本业务功能及相应服务。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        二、用户信息收集的范围与方式
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 28px">
          1、当用户在融智互动网站注册并享受相关服务时，用户理解并同意，我们将可能收集、储存和使用下列与用户信息有关的数据，如果用户不提供或不同意我们收集相关信息，可能无法成为我们的用户或无法订购我们的产品及享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
        </div>

        <div class="main-body" style="margin-top: 26px; margin-left: 22px">
          （1）用户填写和/或提供的信息，包括：姓名、手机号、公司名称以及与为用户提供服务相关的问卷信息等能够单独或者与其他信息结合识别用户身份的信息。
        </div>

        <div class="main-body" style="margin-top: 28px; margin-left: 22px">
          （2）<span class="text-weight">不同终端下获取的信息</span
          >：当用户在软件安装及使用中授予具体权限时，融智互动会接收并记录用户所使用的设备相关信息（例如设备名称、操作系统、浏览器及版本、设备类型、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS位置等传感器信息）、不同终端下获取的信息（例如Openid、用户昵称、用户头像等信息）。融智互动可能会将以上信息进行关联，以便能在不同设备上为用户提供一致的服务。
        </div>

        <div class="main-body" style="margin-top: 27px; margin-left: 22px">
          （3）<span class="text-weight">日志信息</span
          >：当用户使用相关服务时，融智互动会自动收集用户对融智互动服务的使用情况，作为有关网络日志保存。例如：用户的IP地址、浏览信息、访问记录、点击信息、使用语言、访问服务的日期和时间、Cookie、Web
          Beacon等。
        </div>

        <div class="main-body" style="margin-top: 25px; margin-left: 22px">
          （4）<span class="text-weight">设备或应用信息</span
          >：包括用户使用的移动设备、浏览器或其他程序所提供的配置信息、设备版本号、设备识别码等。为了向用户提供更好的服务与改善用户的服务体验，融智互动可能会记录硬件型号、操作系统版本号、国际移动设备识别码（IMEI）、网络设备硬件地址（MAC）等软硬件数据。
        </div>

        <div class="main-body" style="margin-top: 25px; margin-left: 22px">
          （5）<span class="text-weight">从第三方获得的用户的信息</span
          >：融智互动可能通过正当渠道从第三方获取用户的相关信息，在符合相关法律法规规定的前提下，使用从第三方获得的用户信息。
        </div>

        <div class="main-body" style="margin-top: 28px; margin-left: 22px">
          （6）<span class="text-weight">依法豁免征得同意收集和使用的信息</span
          >：包括与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；根据相关法律法规强制性要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的；为维护融智互动其他客户、公司及其关联方的生命、财产等合法权益或维权产品或服务的安全所必需的；用户自行向社会公众公开的，或融智互动从合法公开披露的信息中收集的用户信息的，如合法的新闻报道、政府信息公开等渠道；根据用户的要求签订或履行合同所必需的；学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的用户信息进行去标识化处理的；法律法规规定的其他情形。
        </div>

        <div class="main-body" style="margin-top: 30px">
          2、以上信息范围并非融智互动必然会收集的用户信息内容，融智互动会根据融智互动提供的服务内容选择性收集上述信息范围内与为用户提供服务相关的必要信息。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        三、对Cookie和同类技术的使用
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 23px">
          1、用户理解并同意，当用户在融智互动官网注册并享受融智互动提供的相关服务时，为使融智互动更好地向用户提供服务，我们可能会使用相关技术来收集和存储信息，在此过程中可能会向用户的计算机或移动设备上发送一个或多个Cookie或匿名标识符；Cookie或匿名标识符通常包含标识符、站点名称以及一些号码和字符。借助于
          Cookie技术，网站能够存储用户浏览记录等数据信息。
        </div>

        <div class="main-body" style="margin-top: 24px">
          2、很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not
          Track 请求。但如果用户的浏览器启用了 Do Not
          Track，那么我们会尊重用户的选择。
        </div>

        <div class="main-body" style="margin-top: 24px">
          3、我们不会将Cookie及相关同类技术用于本《隐私政策》所述目的之外的任何其他用途，用户可以根据自己的偏好留存或删除Cookie，也可以清除用户网页中保存的所有Cookie。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">四、用户信息存储</div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 27px">
          1、<span style="font-weight: 600">信息存储地点：</span
          >我们依照法律法规的规定，将在境内运营过程中收集和产生的用户的信息存储于中华人民共和国境内。
        </div>

        <div class="main-body" style="margin-top: 21px">
          2、<span class="text-weight">存储期限</span
          >：在用户未提出修改、删除请求的情况下，我们会安全有效的保存用户信息，仅在向用户提供服务需要的情况下使用用户信息，但法律法规另有规定的除外。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">五、用户信息使用</div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 28px">
          1、为实现提供融智互动相关服务之目的，我们会根据《隐私政策》及授权协议的约定对所收集的用户信息进行使用。如有除此之外的任何其他用途，我们都会提前征得用户的同意。具体而言，我们将为以下目的使用所收集的用户信息：
        </div>

        <div class="main-body" style="margin-top: 20px; margin-left: 22px">
          （1）为了向您提供服务，融智互动及其关联方、合作方会向您发送信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知。
        </div>

        <div class="main-body" style="margin-top: 17px; margin-left: 22px">
          （2）我们可能以用户信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，由关联方或合作方向用户提供具体的产品或服务，但这些统计信息不包含您的任何身份识别信息。
        </div>

        <div class="main-body" style="margin-top: 20px; margin-left: 22px">
          （3）为提高您使用融智互动及其关联方、合作方提供服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或融智互动相关协议、规则的情况，我们可能使用您的会员信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴合法分享的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。
        </div>

        <div class="main-body" style="margin-top: 37px">
          2、在我们通过技术手段对用户信息进行去标识化处理后（为降低其他组织或个人通过去标识化处理后的个人信息识别到用户的风险，我们会采取相应的管理措施，将去标识化后的数据与可用于恢复识别用户的信息分开存储），用户同意并知晓，在此情况下我们有权使用已经去标识化的信息，并在不透露用户信息的前提下，我们有权对用户相关去标识化的信息进行分析并予以商业化的利用。
        </div>

        <div class="main-body" style="margin-top: 34px">
          3、用户知晓并同意，除非用户向融智互动发出修改、删除的通知并经融智互动处理完成，用户在使用融智互动相关服务时所提供的所有信息，将在用户使用融智互动相关服务期间持续授权我们在符合本《隐私政策》的范围内使用。
        </div>

        <div class="main-body" style="margin-top: 37px">
          4、用户知晓并同意，我们会对用户对我们服务的使用情况进行统计，并可能会与第三方共享这些统计信息，以展示我们相关服务的整体使用趋势。但这些统计信息不会包含任何用户信息。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        六、用户信息的共享、转让与公开披露
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 27px">
          1、我们会以高度的勤勉义务对待用户的信息，我们会在取得用户的同意或授权以后，与第三方共享用户的信息，具体包括以下情形：
        </div>

        <div class="main-body" style="margin-top: 29px; margin-left: 22px">
          （1）在获得用户的同意后，我们可能会将用户信息与我们的关联方共享，我们会根据对用户提供服务的现实需要，且受本隐私政策中所声明目的的约束，将必要的用户信息共享给关联方，如因业务需要，确需超出前述授权范围使用用户信息的，将再次征求用户的授权同意。
        </div>

        <div class="main-body" style="margin-top: 27px; margin-left: 22px">
          （2）为实现本《隐私政策》中声明的相关目的，我们的某些服务将由第三方为用户提供。为便于用户享受该等服务，我们会根据合法、正当、必要、特定、目的明确的原则为协助第三方向用户提供服务而使用用户信息。
        </div>

        <div class="main-body" style="margin-top: 31px">
          2、我们会对第三方的数据安全能力进行调查，并要求第三方采取必要的保密和安全措施来存储、使用和处理用户信息，确保用户信息不被泄露。
        </div>

        <div class="main-body" style="margin-top: 26px">
          3、随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让或类似的交易，用户信息有可能作为此类交易的一部分而被转移。在发生前述变更时，我们将要求新的用户信息控制者按照法律法规及不低于本《隐私政策》所要求的安全标准继续保护用户信息。
        </div>

        <div class="main-body text-weight" style="margin-top: 28px">
          4、未经用户事先同意，我们不会向除融智互动及其关联方外的任何第三方，共享用户信息，但下列情形除外：
        </div>

        <div class="main-body" style="margin-top: 27px; margin-left: 22px">
          （1）事先获得用户的明确授权或同意；
        </div>

        <div class="main-body" style="margin-top: 21px; margin-left: 22px">
          （2）用户自行向第三方共享的；
        </div>

        <div class="main-body" style="margin-top: 21px; margin-left: 22px">
          （3）与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；
        </div>

        <div class="main-body" style="margin-top: 22px; margin-left: 22px">
          （4）根据相关法律法规强制性要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的。在符合法律法规的前提下，当我们收到前述披露信息的请求时，我们会要求对方必须出具与之相应的法律文件，如传票或调查函。我们将对所有的请求都进行审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；
        </div>

        <div class="main-body" style="margin-top: 24px; margin-left: 22px">
          （5）在法律法规允许的范围内，为维护融智互动其他用户、公司及其关联方的生命、财产等合法权益或维权产品或服务的安全所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等；
        </div>

        <div class="main-body" style="margin-top: 20px; margin-left: 22px">
          （6）从合法公开披露的信息中收集用户信息的，如合法的新闻报道、政府信息公开等渠道；
        </div>

        <div class="main-body" style="margin-top: 19px; margin-left: 22px">
          （7）根据用户的要求签订或履行合同所必需的；
        </div>

        <div class="main-body" style="margin-top: 26px; margin-left: 22px">
          （8）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
        </div>

        <div class="main-body" style="margin-top: 18px; margin-left: 22px">
          （9）法律法规规定的其他情形。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        七、用户信息安全保护
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 28px">
          1、我们非常重视用户信息的安全，将努力采取各种符合业界标准的合理安全措施（包括技术方面和管理方面）来保护用户信息，防止用户提供的信息被不当使用或被未经授权的访问、公开披露、使用、修改、损坏、丢失或泄漏。
        </div>

        <div class="main-body" style="margin-top: 24px">
          2、我们会使用加密技术、匿名化处理等合理可行的手段保护用户信息，并使用受信赖的保护机制防止用户信息遭到恶意攻击。
        </div>

        <div class="main-body" style="margin-top: 23px">
          3、我们会建立专门的信息安全工作小组、安全管理制度、数据安全流程保障用户信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问用户信息，并适时对数据和技术进行安全审计。
        </div>

        <div class="main-body" style="margin-top: 28px">
          4、尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请用户理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息绝对安全，我们将尽力确保用户提供给我们的信息的安全性。用户知悉并理解，用户接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议用户采取积极措施保护信息的安全。
        </div>

        <div class="main-body" style="margin-top: 31px">
          5、我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止任何安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向用户告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、用户可自主防范和降低风险的建议、对用户的补救措施等。我们将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知用户，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
        </div>

        <div class="main-body text-weight" style="margin-top: 26px">
          6、我们谨此特别提醒用户，本《隐私政策》提供的信息保护措施仅适用于融智互动及关联方提供的产品或服务。我们没有能力及义务保护用户在融智互动相关服务之外的应用、网站提交的任何信息，无论用户登录或浏览上述软件、网站或平台是否基于融智互动的链接或引导，我们对此不承担任何法律责任。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        八、用户信息的管理
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 28px">
          1、我们非常重视用户对信息的管理，并尽全力保护用户对于用户信息的修改（更新或更正）、删除以及撤回授权的权利。
        </div>

        <div class="main-body" style="margin-top: 26px">
          2、用户如需修改（更新或更正）、删除以及撤回用户提供给我们的信息，与我们取得联系，向我们提出有关申请，我们会根据用户的需求为用户提供访问、修改、删除及终止授权服务。用户申请删除信息并终止授权我们向用户提供相关服务的，我们将在我们的系统删除相关信息，并不再向用户提供相关服务，但用户终止授权的决定，不影响此前基于用户的授权而开展的信息处理。
        </div>

        <div class="main-body" style="margin-top: 27px">
          3、用户的信息有以下情形之一时，按照法律法规要求，我们可能无法响应用户的请求：
        </div>

        <div class="main-body" style="margin-top: 26px; margin-left: 22px">
          （1）与国家安全、国防安全有关的；
        </div>

        <div class="main-body" style="margin-top: 22px; margin-left: 22px">
          （2）与公共安全、公共卫生、重大公共利益等有关的；
        </div>

        <div class="main-body" style="margin-top: 21px; margin-left: 22px">
          （3）与犯罪侦查、起诉、审判和执行判决等有关的；
        </div>

        <div class="main-body" style="margin-top: 22px; margin-left: 22px">
          （4）有充分证据表明用户存在主观恶意或滥用权利的；
        </div>

        <div class="main-body" style="margin-top: 20px; margin-left: 22px">
          （5）响应用户的请求将导致用户或其他个人、组织的合法权益受到严重损害的；
        </div>

        <div class="main-body" style="margin-top: 22px; margin-left: 22px">
          （6）涉及商业秘密的；
        </div>

        <div class="main-body" style="margin-top: 21px; margin-left: 22px">
          （7）其他法律法规规定的情形。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        九、未成年人使用条款
      </div>

      <div class="main-body-wrap">
        <div class="main-body text-weight" style="margin-top: 27px">
          若用户是未满18周岁的未成年人，在使用融智互动相关产品或服务前，应在用户的法定监护人监护、指导下共同阅读并同意本《隐私政策》。公司根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护儿童所必要的情况下收集、使用或公开披露未成年人的个人信息。用户及用户的监护人应对用户所提供信息的真实性负责，如我们无法从用户所提供信息判断出用户为未成年人，那么我们不承担保护未成年人隐私权的责任。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        十、隐私政策的生效和变更
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 28px">
          1、为了给用户提供更好的服务，融智互动相关服务将不时更新与变化，我们会适时对《隐私政策》进行修订，该等修订构成《隐私政策》的一部分并具有等同于《隐私政策》的效力。但原则上们不会削减用户依据当前生效的本《隐私政策》所应享受的权利。
        </div>

        <div class="main-body" style="margin-top: 28px">
          2、本《隐私政策》更新后，我们会通过公告或其他适当的方式，说明隐私政策的具体变更内容，以便用户及时了解本《隐私政策》的最新版本，用户知晓并同意我们不需要再向用户作个别的通知。<span
            style="font-weight: 600"
            >若您不同意该修改部分，您应立即与我们联系并停止使用本服务。若您继续使用，视为您同意并接受该修改部分。</span
          >
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        十一、法律适用与争议解决
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 29px">
          1、本协议的成立、生效、履行和解释，均适用中国人民共和国法律；法律无明文规定的，可适用通行的行业惯例。如本协议相关条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对甲乙双方产生法律效力和影响。
        </div>

        <div class="main-body" style="margin-top: 27px">
          2、甲乙双方在履行本协议的过程中，如发生争议，应协商解决。协商不成的，双方均可向融智互动住所地有管辖权的人民法院起诉。
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">
        十二、如何联系我们
      </div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 28px">
          您可以通过以下方式与我们联系，我们将在15天内答复您的请求；
        </div>

        <div class="main-body text-weight" style="margin-top: 19px">
          您可以通过融智互动网站（www.rongzhiyingxiao.com）或通过官网电话（189-8008-9917）与我们联系；
        </div>
      </div>

      <div class="headline-content-paragraph text-weight">十三、其他</div>

      <div class="main-body-wrap">
        <div class="main-body" style="margin-top: 28px">
          1、本《隐私政策》的标题仅为方便及阅读而设，并不影响本《隐私政策》中任何规定的含义或解释。
        </div>

        <div class="main-body" style="margin-top: 22px">
          2、如果用户对本《隐私政策》或其中有关用户信息的收集、保存、使用、共享、披露、保护等功能存在意见或建议时，用户可以通过融智互动反馈渠道反馈意见或投诉。我们会在收到用户的意见及建议后尽快向用户反馈。
        </div>

        <div class="main-body" style="margin-top: 24px">
          3、本《隐私政策》的版权为融智互动所有，在法律允许的范围内，公司保留最终解释和修改的权利。
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer ref="footerWrapRef" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from 'vue'
import Footer from '/@/components/footer/index.vue'
import HeaderWarp from '/@/components/headerWrap/index.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    Footer,
    HeaderWarp
  },
  setup() {
    const homeRef = ref(null)
    const footerWrapRef = ref(null)

    const handleScroll = () => {
      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }
    onMounted(() => {
      homeRef.value.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      homeRef,
      footerWrapRef
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>