<template>
  <div class="about-cantainer" ref="homeRef">
    <!-- 关于我们二级页 -->
    <div class="header-bg">
      <HeaderWarp navActive="关于我们" />

      <div class="header-title">
        <div class="title-box">
          <div class="inte-market-ape">品质服务 用心为您</div>
        </div>
      </div>

      <div class="btn-wrap" @click="clickInfoDia">
        <img
          class="arrow-img arrow-right"
          src="../../assets/image/home/red-right-arrow.png"
          alt=""
        />
        <div class="btn">业务咨询</div>
        <img
          class="arrow-img arrow-left"
          src="../../assets/image/home/red-left-arrow.png"
          alt=""
        />
      </div>
    </div>

    <div class="about-us">
      <!-- <div class="left-wrap">
        <div>
          <div class="text">关</div>
          <div class="text">于</div>
          <div class="text">我</div>
          <div class="text">们</div>
        </div>
        <div class="text-dote"></div>
      </div> -->

      <div class="right-wrap">
        <div class="right-text-wrap">
          <div class="left-text">关于我们</div>
          <div class="text">ABOUT US</div>
        </div>
        <div class="dec">
          成都融智互动科技有限公司自成立以来，扎根于数字化营销业务领域，为客户提供短视频拍摄、广告效果优化、内容营销、全网营销等整合营销服务。团队成员由来自国际4A·与热店的创意伙伴组成，依靠客户伙伴们的支持，凭借创意与努力，获得了诸多行业认可，成为西南乃至全国声名鹊起的品牌数字化营销创意公司。
        </div>
        <div class="dec">
          2021年融智扎根研究全行业增量市场，在金慧集团支持下，开拓了信息流广告代运营、抖音&小红书账号/
          内容运营、直播电商运营等业务，
          建立自有带货账号矩阵、直播达人矩阵，将服务业态做进一步升级与扩大。
        </div>
      </div>
    </div>

    <div class="prize-wrap">
      <img src="../../assets/image/about/about-price.png" alt="" />
    </div>

    <div class="per-title">业绩展示</div>

    <div class="performance-pres">
      <div class="grade-right-wrap">
        <div class="item">
          <img src="../../assets/image/about/about-grand-one.png" alt="" />
          <div class="title">2023年总GMV</div>
        </div>

        <div class="item">
          <img src="../../assets/image/about/about-grand-two.png" alt="" />
          <div class="title">合作客户数</div>
        </div>

        <div class="item">
          <img src="../../assets/image/about/about-grand-three.png" alt="" />
          <div class="title">直播合作场次</div>
        </div>
      </div>

      <span class="data-cutoff">数据截止2023年12月</span>
    </div>

    <!-- <div class="user">合作客户</div>

    <div class="cooperative-user">
      <div class="cooperative-round-wrap" ref="coopRoundRef">
        <img
          v-for="(imgItem, imgIndex) in footerImgs"
          :key="imgIndex"
          :src="imgItem.url"
          alt=""
          class="scroll-img"
          :style="imgIndex == 0 ? '' : 'margin-left: 100px;'"
        />
      </div>
    </div> -->
    <!-- 底部 -->
    <Footer ref="footerWrapRef" />

    <!-- 业务咨询弹窗 -->
    <InformationDialog ref="infoDiaRef" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from 'vue'
import Footer from '/@/components/footer/index.vue'
// import imgUrl from '/@/assets/image/about/about-cooper.png'
import HeaderWarp from '/@/components/headerWrap/index.vue'
import InformationDialog from '/@/components/informationDialog/index.vue'

export default {
  name: 'AboutPage',
  components: {
    Footer,
    HeaderWarp,
    InformationDialog
  },
  setup() {
    const homeRef = ref(null)
    const footerWrapRef = ref(null)
    const coopRoundRef = ref(null)
    const infoDiaRef = ref(null)
    // const footerImgs = ref([
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl },
    //   { url: imgUrl }
    // ])

    // 合作开始滚动
    // const startCoopAnimation = () => {
    //   if (coopRoundRef.value) {
    //     const imgWidth = document.querySelector('.scroll-img').offsetWidth
    //     const totalWidth = imgWidth * footerImgs.value.length
    //     const speed = totalWidth / 2500

    //     coopRoundRef.value.style.animation = `scrollLeft ${speed}s linear infinite`
    //   }
    // }

    // 重置图片位置
    // const getStart = () => {
    //   coopRoundRef.value.style.animation = 'none'
    // }

    const handleScroll = () => {
      // 合作用户滚到底部触发
      // if (coopRoundRef.value) {
      //   const coopFooterRect = coopRoundRef.value.getBoundingClientRect()
      //   const windowHeight = window.innerHeight
      //   if (Math.floor(coopFooterRect.top) <= windowHeight) {
      //     // 到达底部
      //     startCoopAnimation()
      //   } else {
      //     // 离开底部
      //     getStart()
      //   }
      // }

      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }

    // 打开业务咨询弹窗
    const clickInfoDia = () => {
      infoDiaRef.value.open()
    }

    onMounted(() => {
      homeRef.value.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      homeRef,
      footerWrapRef,
      // footerImgs,
      coopRoundRef,
      clickInfoDia,
      infoDiaRef
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>