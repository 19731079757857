import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js';
import dragscroll from '@/utils/directives.js'
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus';

const app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.mount('#app');
app.directive('dragscroll',dragscroll)
document.body.style.margin = '0';
document.title = '融智互动';
