<template>
  <div class="integrated-second-cantainer" ref="homeRef">
    <!-- 第一部分 -->
    <div
      class="tool-one-wrap"
      :style="`background-image: url(${infoData.bgUrl});`"
    >
      <HeaderWarp
        navActive="整合营销"
        :pullActive="infoData.headerTile"
        @pullClick="pullClick"
      />
      <div class="inte-market-ape">{{ infoData.title }}</div>

      <div
        v-for="(decItem, decIndex) in infoData.headerObj?.textList"
        :key="decIndex"
        :class="decItem.class"
        class="inte-market-dec one-line"
      >
        {{ decItem.center }}
      </div>

      <div
        class="tool-btn-wrap"
        :style="
          infoData.headerObj?.textList?.length == 1 ? 'margin-top: 58px' : ''
        "
      >
        <div class="btn-wrap" @click="clickDeConsultant">
          <img
            class="arrow-img arrow-right"
            src="../../assets/image/home/red-right-arrow.png"
            alt=""
          />
          <div class="btn">专属顾问</div>
          <img
            class="arrow-img arrow-left"
            src="../../assets/image/home/red-left-arrow.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="form-wrap">
      <el-form v-model="form" :style="isHorizontalScroll ? `opacity: 0;` : ''">
        <el-form-item class="form-item name" prop="name">
          <el-input v-model="form.name" placeholder="姓名" />
        </el-form-item>

        <el-form-item class="form-item phone" prop="phone">
          <el-input v-model="form.phone" placeholder="电话" />
        </el-form-item>

        <el-form-item class="form-item company" prop="companyName">
          <el-input v-model="form.companyName" placeholder="公司" />
        </el-form-item>

        <el-button @click="submitSave" class="submit-btn">提交</el-button>
      </el-form>
    </div>

    <!-- 第二部分 -->
    <div class="tool-two-wrap" ref="toolTwoWrap">
      <div v-if="infoData.problemObj?.type == 'card'">
        <div class="title">{{ infoData.problemObj?.title }}</div>
        <div class="card-content-wrap" ref="cardContentWrap" v-dragscroll>
          <div class="card-wrap">
            <div
              v-for="(listItem, listIndex) in infoData.problemObj?.list"
              :key="listIndex"
              class="item"
            >
              <img :src="listItem.cardUrl" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="infoData.problemObj?.type == 'list'">
        <div class="title">{{ infoData.problemObj?.title }}</div>
        <div class="list-wrap">
          <div
            v-for="(listItem, listIndex) in infoData.problemObj?.list"
            :key="listIndex"
            class="list-item"
          >
            {{ listItem.title }}
          </div>
        </div>

        <div class="chart-btn-box">
          <div class="chart-btn-wrap free-btn-wrap" @click="clickInfoDia">
            <img
              class="chart-arrow-img chart-arrow-right"
              src="../../assets/image/home/black-right-arrow.png"
              alt=""
            />
            <div class="chart-btn">{{ infoData.problemObj?.btnText }}</div>
            <img
              class="chart-arrow-img chart-arrow-left"
              src="../../assets/image/home/black-left-arrow.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 第三部分 -->
    <div class="tool-three-wrap">
      <div class="title">{{ infoData.exampleObj?.title }}</div>
      <div class="center">{{ infoData.exampleObj?.center }}</div>
      <div class="case-wrap">
        <div
          v-for="(listItem, listIndex) in infoData.exampleObj?.cardList"
          :key="listIndex"
          class="case-item-wrap"
          @click="handleDetail(listItem.id)"
        >
          <img :src="listItem.img" alt="" />
          <div class="listItem-center">{{ listItem.center }}</div>
          <div class="listItem-tag">{{ listItem.tag }}</div>
        </div>
      </div>

      <!-- <div class="more-btn-wrap">
        <span class="btn">查看更多项目</span>
      </div> -->
    </div>

    <Footer ref="footerWrapRef" />

    <!-- 专属顾问 -->
    <DeConsultant ref="deConsultantRef" />

    <!-- 业务咨询弹窗 -->
    <InformationDialog ref="infoDiaRef" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from 'vue'
import HeaderWarp from '/@/components/headerWrap/index.vue'
import Footer from '/@/components/footer/index.vue'
import { useRoute } from 'vue-router'
import { dataList } from '/@/utils/integratedIndex.js'
import { useRouter } from 'vue-router'
import { isPhone } from '/@/utils/validate'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import DeConsultant from '/@/components/deConsultant/index.vue'
import InformationDialog from '/@/components/informationDialog/index.vue'

export default {
  name: 'IntegratedSecondPage',
  components: {
    HeaderWarp,
    Footer,
    DeConsultant,
    InformationDialog
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const homeRef = ref(null)
    const footerWrapRef = ref(null)
    const infoData = ref({})
    const deConsultantRef = ref(null)
    const infoDiaRef = ref(null)
    const toolTwoWrap = ref(null)
    const cardContentWrap = ref(null)

    const form = ref({})
    let isHorizontalScroll = ref(false)
    const threshold = 30 // 阈值，检测接近顶部的距离

    // const saveScrollPosition = () => {
    //   const scrollPosition = {
    //     x: homeRef.value.scrollLeft,
    //     y: homeRef.value.scrollTop
    //   }
    //   // 这里可以将scrollPosition存储到localStorage或者其他合适的地方，比如全局状态管理对象（如Vuex）
    //   localStorage.setItem('lastScrollPosition', JSON.stringify(scrollPosition))
    // }

    // onBeforeRouteLeave(() => {
    //   saveScrollPosition()
    // })

    const getInfo = () => {
      const id = route.params.id
      dataList.forEach((item) => {
        if (item.id == id) {
          infoData.value = item
        }
      })
    }

    const pullClick = (pull) => {
      if (pull.id) {
        router.push({ name: 'IntegratedSecond', params: { id: pull.id } })
        getInfo()
      }
    }

    const handleDetail = (id) => {
      if (id) {
        router.push({ name: 'CaseDetail', params: { id: id } })
      }
    }

    // 提交
    const submitSave = async () => {
      if (form.value.phone) {
        if (!isPhone(form.value.phone)) {
          ElMessage.error('请填写正确手机号或者固话号')
        } else {
          await axios
            .post(
              'http://platform.rongzhiyingxiao.com/api/public/saveClue',
              form.value
            )
            .then(() => {
              ElMessage.success({
                message: '提交成功',
                type: 'success'
              })
            })
            .catch(() => {
              // 请求失败后的处理逻辑
              ElMessage.error('请求失败，错误信息')
            })
        }
      } else {
        ElMessage.error('请填写手机号')
      }
    }

    // 打开专属顾问
    const clickDeConsultant = () => {
      deConsultantRef.value.open()
    }

    // 打开业务咨询弹窗
    const clickInfoDia = () => {
      infoDiaRef.value.open()
    }

    const handleScroll = () => {
      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }

      if (toolTwoWrap.value && infoData.value.problemObj?.type == 'card') {
        const rect = toolTwoWrap.value.getBoundingClientRect()
        // 页面朝下滚动，已经超过阈值了或在阈值内，且cardContentWrap模块不是滚动到最右边，那么可以定位住开始横向滚动cardContentWrap
        if (
          rect.top <= threshold &&
          cardContentWrap.value.scrollLeft <
            cardContentWrap.value.scrollWidth -
              cardContentWrap.value.clientWidth &&
          !isHorizontalScroll.value
        ) {
          isHorizontalScroll.value = true
          homeRef.value.style.overflowY = 'hidden'
          cardContentWrap.value.style.overflowX = 'auto'
        } else if (
          rect.top >= threshold &&
          cardContentWrap.value.scrollLeft > 0 &&
          !isHorizontalScroll.value
        ) {
          // 页面朝上滚动，已经超过阈值了或在阈值内，且cardContentWrap模块不是滚动到最左边，那么可以定位住开始横向滚动cardContentWrap
          isHorizontalScroll.value = true
          homeRef.value.style.overflowY = 'hidden'
          cardContentWrap.value.style.overflowX = 'auto'
        }
      }
    }

    const handleWheel = (event) => {
      if (isHorizontalScroll.value) {
        cardContentWrap.value.scrollLeft += event.deltaY
        event.preventDefault()
        // 横向滚动到最后，还在向下滚动的话,恢复竖向滚动
        if (
          cardContentWrap.value.scrollLeft >=
          cardContentWrap.value.scrollWidth - cardContentWrap.value.clientWidth
        ) {
          isHorizontalScroll.value = false
          homeRef.value.style.overflowY = 'auto'
          cardContentWrap.value.style.overflowX = 'hidden'
        }
        // 横向滚动到最左边，还在向上滚动的话,恢复竖向滚动
        if (cardContentWrap.value.scrollLeft <= 0) {
          isHorizontalScroll.value = false
          homeRef.value.style.overflowY = 'auto'
          cardContentWrap.value.style.overflowX = 'hidden'
        }
      }
    }

    onMounted(() => {
      homeRef.value.addEventListener('scroll', handleScroll)
      homeRef.value.addEventListener('wheel', handleWheel)
      getInfo()
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
      homeRef.value.removeEventListener('wheel', handleWheel)
    })

    return {
      homeRef,
      footerWrapRef,
      deConsultantRef,
      infoDiaRef,
      toolTwoWrap,
      infoData,
      pullClick,
      handleDetail,
      getInfo,
      form,
      submitSave,
      clickDeConsultant,
      clickInfoDia,
      cardContentWrap,
      isHorizontalScroll
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>