<template>
  <div>
    <el-dialog
      v-model="visible"
      width="30%"
      append-to-body
      align-center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
    >
      <div class="deConsultant-dialog-wrap">
        <div class="title">联系专属顾问</div>
        <div class="content">选择我们，开启您的营销新篇章</div>
        <div class="img">
          <img
            src="../../assets/image/deConsultant/consultant-code.png"
            alt=""
          />
        </div>
        <div class="tips">扫码立即获取</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'DeConsultant',
  setup() {
    const visible = ref(false)

    const open = () => {
      visible.value = true
    }

    return {
      visible,
      open
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>