import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/home/index.vue';
import Integrated from '../views/integrated/index.vue'
import IntelligentTool from '../views/intelligentTool/index.vue'
import BrandIncubation from '../views/brandIncubation/index.vue'
import About from '../views/about/index.vue'
import CaseDetail from '../views/caseDetail/index.vue'
import IntegratedSecond from '../views/integratedSecond/index.vue'
import PrivacyPolicy from '../views/privacyPolicy/index.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/integrated',
    name: 'Integrated',
    component: Integrated,
    children: [
      {
        path: 'secondLevel/:id',
        name: 'IntegratedSecond',
        component: IntegratedSecond,
        meta: {
          keepAlive: false
        }
      },
    ],
  },
  {
    path: '/intelligentTool',
    name: 'IntelligentTool',
    component: IntelligentTool
  },
  {
    path: '/brandIncubation',
    name: 'BrandIncubation',
    component: BrandIncubation
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: `/caseDetail/:id`,
    name: 'CaseDetail',
    component: CaseDetail
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// 全局前置路由守卫
router.beforeEach((to, from, next) => {
  // 判断是否是IntegratedSecond子路由
  if (to.name === 'IntegratedSecond') {
    // 这里可以添加针对IntegratedSecond子路由的特定逻辑，比如权限验证等
    // 如果没有特定逻辑，直接放行
    next();
  } else if (to.name === 'Integrated') {
    // 针对Integrated父路由的逻辑，这里可以根据需求添加或保留原来的逻辑
    const firstChildRoute = router.getRoutes().find((route) => {
      return route.name === 'Integrated' && route.children && route.children.length > 0;
    })?.children[0];
    if (firstChildRoute) {
      next({ name: 'IntegratedSecond', params: { id: 'localLife' } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;