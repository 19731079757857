<template>
  <div>
    <div class="footer-wrap">
      <div class="footer-dec">
        <div class="logo-wrap">
          <img
            style="height: 56px"
            src="../../assets/image/home/footer-logo.png"
            alt=""
          />
        </div>
        <div class="contacts-wrap">
          <div class="contacts">Contacts</div>
          <div class="phone">189-8008-9917</div>
          <div class="phone" style="margin-bottom: 27px">173-8015-0726</div>
          <div class="poi">中国·成都·武侯区高新科技工业园F座1楼</div>
          <div class="poi">went@kingwisoft.com</div>
          <div class="poi">
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="(新页面打开)"
              href="https://beian.miit.gov.cn/"
            >
              蜀ICP备：2024100140号-1
            </a>
          </div>
        </div>
      </div>

      <div class="round-wrap" ref="roundRef">
        <img
          v-for="(imgItem, imgIndex) in footerImgs"
          :key="imgIndex"
          :src="imgItem.url"
          alt=""
          class="scroll-img"
          :style="imgIndex == 0 ? '' : 'margin-left: 100px;'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import imgUrl from '/@/assets/image/home/home-go-round.png'

export default {
  name: 'FooterPage',
  components: {},
  setup() {
    const roundRef = ref(null)
    const footerImgs = ref([
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl }
    ])

    // 开始滚动
    const startAnimation = () => {
      if (roundRef.value) {
        const imgWidth = document.querySelector('.scroll-img').offsetWidth
        const totalWidth = imgWidth * footerImgs.value.length
        const speed = totalWidth / 150

        roundRef.value.style.animation = `scrollLeft ${speed}s linear infinite`
      }
    }

    // 重置图片位置
    const getStart = () => {
      roundRef.value.style.animation = 'none'
    }

    return {
      footerImgs,
      imgUrl,
      roundRef,
      startAnimation,
      getStart
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>