// 案例详情页面配置
export const dataList = [
  {
    id: 'chff',
    headerObj: {
      title: '巨量千川代运营-达人',
      headerImg: new URL(`@/assets/image/detail/chff-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line chff-line">
            单日GMV<span class="hight">2.3亿+</span>，单场涨粉<span class="hight"
              >40w+</span
            >，场峰值场观<span class="hight">50W+</span>
          </div>
          <div class="line chff-line">
            登顶<span class="hight">带货榜NO.1</span>，远超客户预期
          </div>
        </div>`
    },
    recordObj: {
      title: '@彩虹夫妇 ｜ 直播间引流',
      recordImg: new URL(`@/assets/image/detail/chff-record.png`, import.meta.url).href,
      recordList: [
        { name: '单日GMV', record: '2.3亿', unit: '+'},
        { name: '单场涨粉', record: '40w', unit: '+'},
        { name: '总成交单量', record: '280w', unit: '+'},
        { name: '总观看人数', record: '3700w', unit: '+'},
        { name: '峰值场观', record: '50w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 60px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/particular-imgs-broadcast-room.png`, import.meta.url).href, title: '直播间', width: '245px' },
        { url: new URL(`@/assets/image/detail/particular-imgs-background-data.png`, import.meta.url).href, title: '巨量千川后台数据', width: '617px' },
      ]
    }
  },
  {
    id: 'hqls',
    headerObj: {
      title: '红旗连锁直播投流',
      headerImg: new URL(`@/assets/image/detail/hqls-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line hqls-line">
            通过「IP品专联动+券卡灵活组品+直播玩法创新」，本地推精细化分段式投放助力GMV收割
          </div>
          <div class="line hqls-line">
            单日直播<span class="hight">GMV突破1亿+，</span>打爆成都本地商超标杆!
          </div>
        </div>`
    },
    recordObj: {
      title: '',
      recordImg: new URL(`@/assets/image/detail/hqls-record.png`, import.meta.url).href,
      recordList: [
        { name: '全国团购综合类目', record: 'TOP1', unit: ''},
        { name: '总曝光量', record: '1亿', unit: '+'},
        { name: '总GMV', record: '1亿', unit: '+'},
        { name: '新增粉丝', record: '30w', unit: '+'},
        { name: '总场观', record: '600w', unit: '+'},
        { name: '峰值场观', record: '14w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 50px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/hqls-particular-imgs-one.png`, import.meta.url).href, title: '投放战报', width: '245px' },
        { url: new URL(`@/assets/image/detail/hqls-particular-imgs-two.png`, import.meta.url).href, title: '开屏广告', width: '245px' },
        { url: new URL(`@/assets/image/detail/hqls-particular-imgs-three.png`, import.meta.url).href, title: '搜索品专', width: '245px' },
        { url: new URL(`@/assets/image/detail/hqls-particular-imgs-four.png`, import.meta.url).href, title: '券卡灵活组品', width: '245px' },
      ]
    }
  },
  {
    id: 'tescy',
    headerObj: {
      title: '太二酸菜鱼直播投流',
      headerImg: new URL(`@/assets/image/detail/tescy-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line tescy-line">
            联动抖音平台<span class="hight">畅吃聚会日IP</span>开启全国场首播，开播十二小时GMV<span class="hight">突破1亿</span>
          </div>
          <div class="line tescy-line">
           直播期间<span class="hight">登顶抖音团购带货榜第一名</span>
          </div>
        </div>`
    },
    recordObj: {
      title: '太二酸菜鱼 ｜ 全国场首播',
      recordImg: new URL(`@/assets/image/detail/tescy-record.png`, import.meta.url).href,
      recordList: [
        { name: '全国团购带货榜', record: 'TOP1', unit: ''},
        { name: '总曝光量', record: '1亿', unit: '+'},
        { name: '总GMV', record: '1.7亿', unit: '+'},
        { name: '总场观', record: '716w', unit: '+'},
        { name: '峰值场观', record: '2w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 50px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/tescy-particular-imgs-one.png`, import.meta.url).href, title: '投放战报', width: '245px' },
        { url: new URL(`@/assets/image/detail/tescy-particular-imgs-two.png`, import.meta.url).href, title: '直播间团购', width: '620px' },
      ]
    }
  },
  {
    id: 'ydddc',
    headerObj: {
      title: '雅迪电动车直播投流',
      headerImg: new URL(`@/assets/image/detail/ydddc-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line ydddc-line">
            雅迪电动车品牌首播投流，<span class="hight">GMV破峰2000w+，</span>总曝光量达成<span class="hight">5000w+</span>
          </div>
          <div class="line ydddc-line">
           登顶区域<span class="hight">团购带货榜TOP1，</span>成为<span class="hight">电动车行业</span>在<span class="hight">抖音本地生活</span>的标志性案例!
          </div>
        </div>`
    },
    recordObj: {
      title: '雅迪首播，福利FUN送',
      recordImg: new URL(`@/assets/image/detail/ydddc-record.png`, import.meta.url).href,
      recordList: [
        { name: '区域团购带货榜', record: 'TOP1', unit: ''},
        { name: '总曝光量', record: '5000w', unit: '+'},
        { name: '总GMV', record: '2000w', unit: '+'},
        { name: '新增粉丝', record: '6w', unit: '+'},
        { name: '订单量', record: '3w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 50px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/ydddc-particular-imgs-one.png`, import.meta.url).href, title: '投放战报', width: '245px' },
        { url: new URL(`@/assets/image/detail/ydddc-particular-imgs-two.png`, import.meta.url).href, title: '引流短视频', width: '513px' },
      ]
    }
  },
  {
    id: 'hls',
    headerObj: {
      title: '华莱士新品整合营销',
      headerImg: new URL(`@/assets/image/detail/hls-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line hls-line">
            试水众测任务，低成本合作<span class="hight">300+</span>达人，海量人群拉新，助力大场真播转化
          </div>
          <div class="line hls-line">
           话题曝光量达<span class="hight">7亿+，</span>合作达人总投稿视频<span class="hight">3440+条，</span>投流助推<span class="hight">GMV提升</span>
          </div>
        </div>`
    },
    recordObj: {
      title: '华莱士国民汉堡',
      recordImg: new URL(`@/assets/image/detail/hls-record.png`, import.meta.url).href,
      recordList: [
        { name: '话题曝光量', record: '7亿', unit: '+'},
        { name: '品牌拉新规模', record: '390w', unit: '+'},
        { name: '总投稿视频', record: '3400', unit: '+'},
        { name: '场观人数', record: '10w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 70px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/hls-particular-imgs-one.png`, import.meta.url).href, title: '全民任务', width: '245px' },
        { url: new URL(`@/assets/image/detail/hls-particular-imgs-two.png`, import.meta.url).href, title: '创意直播', width: '245px' },
        { url: new URL(`@/assets/image/detail/hls-particular-imgs-three.png`, import.meta.url).href, title: '达人合作', width: '245px' },
        { url: new URL(`@/assets/image/detail/hls-particular-imgs-four.png`, import.meta.url).href, title: '抖音话题', width: '245px' },
      ]
    }
  },
  {
    id: 'hsay',
    headerObj: {
      title: '沪上阿姨区域挑战赛',
      headerImg: new URL(`@/assets/image/detail/hsay-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line hsay-line">
            沪上阿姨10周年品牌换新，通过抖音<span class="hight">区域挑战赛+商业话题+硬广资源</span>推广
          </div>
          <div class="line hsay-line">
           达成<span class="hight">1.3亿次</span>话题曝光，全民任务播放量<span class="hight">4311w+，</span>互动量<span class="hight">312w+</span>
          </div>
        </div>`
    },
    recordObj: {
      title: '沪上阿姨十周年福利',
      recordImg: new URL(`@/assets/image/detail/hsay-record.png`, import.meta.url).href,
      recordList: [
        { name: '话题播放量', record: '1.3亿', unit: '+'},
        { name: '任务播放量', record: '4311w', unit: '+'},
        { name: '视频互动量', record: '312w', unit: '+'},
        { name: '参与人数', record: '6000', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 70px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/hsay-particular-imgs-one.png`, import.meta.url).href, title: '商业话题', width: '245px' },
        { url: new URL(`@/assets/image/detail/hsay-particular-imgs-two.png`, import.meta.url).href, title: '开屏广告', width: '245px' },
        { url: new URL(`@/assets/image/detail/hsay-particular-imgs-three.png`, import.meta.url).href, title: '内容热推', width: '245px' },
        { url: new URL(`@/assets/image/detail/hsay-particular-imgs-four.png`, import.meta.url).href, title: '信息流广告', width: '245px' },
      ]
    }
  },
  {
    id: 'cdjdhy',
    headerObj: {
      title: '成都极地海洋公园',
      headerImg: new URL(`@/assets/image/detail/cdjdhy-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line">
            结合山海经主题打造<span class="hight">7位特色NPC</span>
          </div>
          <div class="line">
           春节累计抖音热榜上榜<span class="hight">3次，</span>同城榜<span class="hight">霸榜7天</span>
          </div>
        </div>`
    },
    recordObj: {
      title: '',
      recordImg: new URL(`@/assets/image/detail/cdjdhy-record.png`, import.meta.url).href,
      recordList: [
        { name: '首发当日成都同城榜', record: 'TOP1', unit: ''},
        { name: '全国种草榜', record: 'TOP6', unit: ''},
        { name: '话题累积播放量', record: '600w', unit: '+'},
        { name: '热度累计高达', record: '1300w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 50px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/cdjdhy-particular-imgs-one.png`, import.meta.url).href, title: '', width: '245px' },
        { url: new URL(`@/assets/image/detail/cdjdhy-particular-imgs-two.png`, import.meta.url).href, title: '', width: '245px' },
        { url: new URL(`@/assets/image/detail/cdjdhy-particular-imgs-three.png`, import.meta.url).href, title: '', width: '245px' },
        { url: new URL(`@/assets/image/detail/cdjdhy-particular-imgs-four.png`, import.meta.url).href, title: '', width: '245px' },
      ]
    }
  },
  {
    id: 'cdhlg',
    headerObj: {
      title: '成都欢乐谷年度营销',
      headerImg: new URL(`@/assets/image/detail/cdhlg-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line cdhlg-line">
            作为成都欢乐谷的年框合作服务商，负责欢乐谷<span class="hight">全年营销推广</span>
          </div>
          <div class="line cdhlg-line">
           包含抖音<span class="hight">热点策划、短视频拍摄、信息流投放、直播投流</span>及<span class="hight">内容服务</span>等
          </div>
          <div class="line cdhlg-line">
           万圣奇幻夜唐僧NPC<span class="hight">火爆全网   </span>电音节、灯光节、儿童节等节点累计登上<span class="hight">抖音热榜19次</span>
          </div>
        </div>`
    },
    recordObj: {
      title: '成都欢乐谷年度整合营销',
      recordImg: new URL(`@/assets/image/detail/cdhlg-record.png`, import.meta.url).href,
      recordList: [
        { name: '挑战赛增量曝光', record: '3.3亿', unit: '+'},
        { name: '万圣奇幻夜新增曝光', record: '2亿', unit: '+'},
        { name: '抖音同城热榜TOP10', record: '19次', unit: ''},
        { name: '唐僧话题登顶全国榜', record: 'TOP1', unit: ''},
        { name: '合作达人数', record: '2000', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 70px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/cdhlg-particular-imgs-one.png`, import.meta.url).href, title: '', width: '245px' },
        { url: new URL(`@/assets/image/detail/cdhlg-particular-imgs-two.png`, import.meta.url).href, title: '', width: '245px' },
        { url: new URL(`@/assets/image/detail/cdhlg-particular-imgs-three.png`, import.meta.url).href, title: '', width: '245px' },
      ],
      particularDecObj: {
        type: 'mulImg',
        title: '欢乐谷整合营销主要执行内容',
        list: [
          [{ url: new URL(`@/assets/image/detail/cdhlg-particular-part-one.png`, import.meta.url).href, title: '短视频拍摄', width: '513px' },{ url: new URL(`@/assets/image/detail/cdhlg-particular-part-two.png`, import.meta.url).href, title: '热点策划', width: '513px' }],
          [{ url: new URL(`@/assets/image/detail/cdhlg-particular-part-three.png`, import.meta.url).href, title: '达人安排', width: '1048px' }],
          [{ url: new URL(`@/assets/image/detail/cdhlg-particular-part-four.png`, import.meta.url).href, title: '本地推投流', width: '1048px' }],
        ]
      }
    }
  },
  {
    id: 'lzyd',
    backgroundClass: 'height-lzyd',
    headerObj: {
      title: '泸州移动',
      headerImg: new URL(`@/assets/image/detail/lzyd-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line">
            面对抖音新媒体复杂多变的运营环境和技术要求，帮助
          </div>
          <div class="line">
           运营商内部员工提高普遍缺乏的专业知识和技能。
          </div>
        </div>`
    },
    recordObj: {
      recordList: [
        { name: '区域品牌曝光量提高', record: '800', unit: '%+'},
        { name: '协助橱窗短视频发布数量', record: '80条', unit: '+'},
        { name: '区域运营商新媒体参与率', record: '95', unit: '%+'},
        { name: '抖音渠道到店量', record: '300', unit: '+'},
      ],
      recordRClass: 'lzyd-recordR'
    },
    particularObj: {
      type: 'imgTrans',
      list: [
        { url: new URL(`@/assets/image/detail/lzyd-particular-imgs-one.png`, import.meta.url).href, title: '培训图片', width: '564px' },
      ]
    }
  },
  {
    id: 'lx',
    backgroundClass: 'height-lx',
    headerObj: {
      title: '理想汽车',
      headerImg: new URL(`@/assets/image/detail/lx-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line">
            帮助广州理想尽快完成汽车营销模式和“打法”的转变
          </div>
          <div class="line">
           做好新媒体转型，解决流量入口问题
          </div>
        </div>`
    },
    recordObj: {
      recordList: [
        { name: '单账号直播线索突破', record: '2000+'},
        { name: '单店单月线索量同比增长', record: '1000%+'},
        { name: '单月直播曝光人数', record: '200w+'},
        { name: '单账号抖音成交单数', record: '30+'},
      ],
      recordRClass: 'lx-recordR'
    },
    particularObj: {
      type: 'imgTrans',
      list: [
        { url: new URL(`@/assets/image/detail/lx-particular-imgs-one.png`, import.meta.url).href, title: '', width: '564px' },
      ]
    }
  },
  {
    id: 'xhs',
    headerObj: {
      title: '小红书内容运营',
      headerImg: new URL(`@/assets/image/detail/xhs-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line">
            洞悉品牌/行业数据，快速提升代理商/客户对<span class="hight">小红书商业产品认知力</span>
          </div>
        </div>`
    },
    recordObj: {
      recordImg: new URL(`@/assets/image/detail/xhs-record.png`, import.meta.url).href,
      recordList: [
        { name: '单篇平均阅读量', record: '3w', unit: '+'},
        { name: '打造多篇爆文总获赞', record: '2.7w', unit: '+'},
        { name: '半年内新增粉丝', record: '3.2w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 50px'
    },
    particularObj: {
      type: 'decList',
      title: '案例成果',
      list: [
        { title: '策划专栏内容，多角度输出营销干货知识', decStr: `<div class="xc-descr-line">
              <div class="line">
                策划【新品速递】【行业情报局】 【互联网黑化】【情报官的可爱日常】【情报官小课
              </div>
              <div class="line">
                堂】等专栏内容，从产品使用、行业洞察、品牌策略等角度输出营销干货，提升代理商/
              </div>
              <div class="line">客户对于蒲公英、聚光、薯条等平台/产品理解。</div>
            </div>`, imgs: [{ url: new URL(`@/assets/image/detail/xhs-particular-imgs-one.png`, import.meta.url).href, width: '784px' }] },
        { title: '打造创意IP，沉淀品牌内容资产', decStr: `<div class="xc-descr-line">
          <div class="line">
            从产品出发，实时关注产品功能更新迭代，用最简洁的图让用户了解产品。打造「聚光
          </div>
          <div class="line">薯」形象IP，通过表情包、创意海报、手机壁纸等内容制造热点，丰富品牌形象。</div>
        </div>`, imgs: [{ url: new URL(`@/assets/image/detail/xhs-particular-imgs-two.png`, import.meta.url).href, width: '784px' }] },
      ]
    }
  },
  {
    id: 'tfld',
    headerObj: {
      title: '天府绿道官抖及矩阵号代运营',
      headerImg: new URL(`@/assets/image/detail/tfld-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line">
            运营3个月就将账号在成都市政务类抖音账号排名从50名开外提升至<span class="hight">前5名</span>
          </div>
          <div class="line">
            多条热门视频登上成都同城榜<span class="hight">TOP3，</span>被其他媒体转载
          </div>
          <div class="line">
            氚星人账号持续创作发布视频内容，多条视频的观看次数都达到了百万级
          </div>
        </div>`
    },
    recordObj: {
      title: '',
      recordImg: new URL(`@/assets/image/detail/tfld-record.png`, import.meta.url).href,
      recordTitle: '成都天府绿道账号代运营 ',
      recordDecStr: `<div class="record-dec-Str">
        <div class="line">打造一个接地气又贴心的官方号形象，利用抖音平台特性</div>
        <div class="line">正向宣传绿道农商文旅体各项目的特色。</div>
      </div>
      `,
      recordList: [
        { name: '本地政务类排名', record: 'TOP5', unit: ''},
        { name: '多次登同城榜', record: 'TOP3', unit: ''},
        { name: '账号合计粉丝增长', record: '2w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 60px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/tfld-particular-imgs-one.png`, import.meta.url).href, title: '天府绿道官方账号代运营 ', width: '513px' },
      ],
      particularDecObj: {
        type: 'mulFormat',
        list: [
          [{ url: new URL(`@/assets/image/detail/cdhlg-particular-part-one.png`, import.meta.url).href, title: '短视频拍摄', width: '513px' },{ url: new URL(`@/assets/image/detail/cdhlg-particular-part-two.png`, import.meta.url).href, title: '热点策划', width: '513px' }],
          [{ url: new URL(`@/assets/image/detail/cdhlg-particular-part-three.png`, import.meta.url).href, title: '达人安排', width: '1048px' }],
          [{ url: new URL(`@/assets/image/detail/cdhlg-particular-part-four.png`, import.meta.url).href, title: '本地推投流', width: '1048px' }],
        ]
      },
      recordObj: {
        recordImg: new URL(`@/assets/image/detail/tfld-record.png`, import.meta.url).href,
        recordTitle: '氚星人研究所账号代运营  ',
        recordDecStr: `<div class="record-dec-Str">
          <div class="line">以熊猫为IP形象，打造四川本地高话题度的文旅宣传账号，</div>
          <div class="line">风格活泼有趣，吸引年轻用户关注。</div>
        </div>
        `,
        recordList: [
          { name: '本地政务类排名', record: 'TOP5', unit: ''},
          { name: '多次登同城榜', record: 'TOP3', unit: ''},
          { name: '账号合计粉丝增长', record: '2w', unit: '+'},
        ],
        mulImg: [
          { url: new URL(`@/assets/image/detail/tfld-particular-record-part-one.png`, import.meta.url).href, title: '账号视频选题&内容创作', width: '1048px' }
        ]
      }
    }
  },
  {
    id: 'bgy',
    headerObj: {
      title: '百果园团购&团购优选',
      headerImg: new URL(`@/assets/image/detail/bgy-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line bgy-line">
            百果园团购项目运营期间，短视频引流直播间GMV突破<span class="hight">500w+</span>
          </div>
          <div class="line bgy-line">
            团购优选账号运营期间视频互动率<span class="hight">超30%，</span>高于平台平均水准<span class="hight">10倍</span>
          </div>
        </div>`
    },
    recordObj: {
      recordImg: new URL(`@/assets/image/detail/bgy-record.png`, import.meta.url).href,
      recordList: [
        { name: '视频引流直播间GMV', record: '500w+'},
        { name: '账号互动率', record: '30%+'},
        { name: '互动率高于平均', record: '10倍'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 60px',
      recordTitle: '特色产品引流视频+店铺直播 ',
      recordDecStr: `<div class="record-dec-Str">
        <div class="line">蓝V账号，基于优质水果核心卖点创作沉浸式剥水果的创</div>
        <div class="line">意内容，达成粉丝增长和精准营销目的。</div>
      </div>
      `,
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/bgy-particular-imgs-one.png`, import.meta.url).href, title: '自有达人直播  ', width: '513px' },
        { url: new URL(`@/assets/image/detail/bgy-particular-imgs-two.png`, import.meta.url).href, title: '引流视频制作  ', width: '513px' },
      ]
    }
  },
  {
    id: 'xc',
    headerObj: {
      title: '喜茶年度盛典抖音直播投放',
      headerImg: new URL(`@/assets/image/detail/xc-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line xc-line">
            喜茶，作为新茶饮行业的开创者，2012年喜茶诞生于广东江门江边里，通
          </div>
          <div class="line xc-line">
            过使用真奶、真茶首创芝士茶，开创了整个新茶饮行业。在2023元旦前夕
          </div>
          <div class="line xc-line">
            开启抖音大场直播，在短视频领域通过#2023喜茶年度盛典#热点话题撬动
          </div>
          <div class="line xc-line">
            ugc互动参与线上线下联动打卡。
          </div>
        </div>`
    },
    recordObj: {
      recordImg: new URL(`@/assets/image/detail/xc-record.png`, import.meta.url).href,
      recordList: [
        { name: '直播GMV', record: '4600w', unit: '+'},
        { name: '直播成交下单', record: '200w', unit: '+'},
        { name: '新增粉丝', record: '21w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 50px'
    },
    particularObj: {
      type: 'decList',
      title: '投流策略',
      list: [
        { title: '前2小时标准放量拉升+高峰期切全域', decStr: `<div class="xc-descr-line">
              <div class="line">
                根据巨量云图5A人群分析得知，目前<span class="hight"
                  >品牌拉新</span
                >是最大痛点，故开播配合福袋/福利品/货
              </div>
              <div class="line">
                补及品牌+搜索人群不断触达人群，提高在本地生活直播圈层的流量比重，突破3.4W+在
              </div>
              <div class="line">线，实时调整计划。</div>
            </div>` },
        { title: '次日调优人群+全域搭配浅层+搜索', decStr: `<div class="xc-descr-line">
          <div class="line">
            开场前1小时使用标准投放，重点放前一日转化好的人群，切全域后实时沟通全域产品给
          </div>
          <div class="line">到支持，调优效果同时搭配搜索浅层，在下午时间段保持稳定增长。</div>
        </div>` },
        { title: '新增测试人群+标准/全域合理利用', decStr: `<div class="xc-descr-line">
          <div class="line">
            第三日场观及增速都较为稳定，上午场主跑标准计划，新测试高客单友商人群及高海宁人
          </div>
          <div class="line">群，晚上再配合全域高峰期进行最后冲高，浅层测试榜单排名靠前品牌及喜茶人群包。</div>
        </div>` },
      ]
    }
  },
  {
    id: 'xlxs',
    headerObj: {
      title: '西岭雪山整合营销',
      headerImg: new URL(`@/assets/image/detail/xlxs-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line xlxs-line">
            直播期间连续霸榜<span class="hight">抖音团购带货榜周榜&月榜TOP1</span>
          </div>
          <div class="line xlxs-line">
           合作头部垂类KOL视频播放总量<span class="hight">400w+</span>
          </div>
        </div>`
    },
    recordObj: {
      title: '# 安逸走成都 冬趣西岭雪 #',
      recordImg: new URL(`@/assets/image/detail/xlxs-record.png`, import.meta.url).href,
      recordList: [
        { name: '整体曝光', record: '600w', unit: '+'},
        { name: '整体GMV', record: '1000w', unit: '+'},
        { name: '直播涨粉', record: '5400', unit: '+'},
        { name: '总场观数据', record: '15w', unit: '+'},
        { name: '峰值场观', record: '1w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 60px'
    },
    particularObj: {
      type: 'imgList',
      list: [
        { url: new URL(`@/assets/image/detail/xlxs-particular-imgs-one.png`, import.meta.url).href, title: '创意视觉展示  ', width: '905px' },
      ]
    }
  },
  {
    id: 'mxbc',
    headerObj: {
      title: '蜜雪冰城9月区域号日播',
      headerImg: new URL(`@/assets/image/detail/mxbc-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line mxbc-line">
            提升账号权重，创造单月佳绩
          </div>
        </div>`
    },
    recordObj: {
      title: '',
      recordImg: new URL(`@/assets/image/detail/mxbc-record.png`, import.meta.url).href,
      recordList: [
        { name: '9月直播总GMV', record: '770w', unit: '+'},
        { name: '直播成交下单', record: '80w', unit: '+'},
        { name: '新增粉丝', record: '2w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 200px'
    }
  },
  {
    id: 'cbd',
    headerObj: {
      title: '茶百道直播投流',
      headerImg: new URL(`@/assets/image/detail/cbd-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line cbd-line">
            四次全国场直播GMV<span class="hight">持续破峰</span>
          </div>
          <div class="line cbd-line">
            各项数据维度均<span class="hight">突破行业峰值</span>，品牌账户粉丝<span class="hight">突破百万</span>，成为茶饮赛道<span class="hight">TOP.1</span>
          </div>
          <div class="line cbd-line">
            助力茶百道成为抖音生活服务全行业<span class="hight">首个直播破亿</span>品牌
          </div>
        </div>`
    },
    recordObj: {
      title: '茶百道 ｜ 四次直播专场GMV',
      recordImg: new URL(`@/assets/image/detail/cbd-record.png`, import.meta.url).href,
      recordList: [
        { name: '2023/03/10', record: '1亿', unit: '+'},
        { name: '2022/10/24', record: '8320w', unit: '+'},
        { name: '2022/05/13', record: '5200w', unit: '+'},
        { name: '2021/12/31', record: '1500w', unit: '+'},
      ],
      recordStyle: 'background-color: #FFF5F5; padding-bottom: 77px'
    },
    particularObj: {
      type: 'imgList',
      decStr: `<div class="descr-line">
          <div class="line">
            融智与茶百道的合作获得了巨量旗下商业化综合价值奖项-<span class="hight">共擎奖</span>
          </div>
        </div>`,
      list: [
        { url: new URL(`@/assets/image/detail/cbd-particular-imgs-one.png`, import.meta.url).href, title: '共擎奖-区域营销案例奖  ', width: '906px' },
      ]
    }
  },
  {
    id: 'sls',
    backgroundClass: 'height-sls',
    headerObj: {
      title: '舒乐时抖音旗舰店',
      headerImg: new URL(`@/assets/image/detail/sls-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line">
            中国新锐母婴从0到1打造<span class="hight">抖音垂类爆款TOP级品牌</span>
          </div>
          <div class="line">
            通过明星联动、爆款内容策划及直播团队成功打爆单品，实现销量突破
          </div>
        </div>`
    },
    recordObj: {
      type: 'notRecordList',
      title: '抖in爆款榜',
      recordImg: new URL(`@/assets/image/detail/sls-record.png`, import.meta.url).href,
      top: 'NO.2',
      rightUrl: new URL(`@/assets/image/detail/sls-hot-fashion.png`, import.meta.url).href,
    },
    particularObj: {
      type: 'imgListTop',
      list: [
        { url: new URL(`@/assets/image/detail/sls-particular-imgs-one.png`, import.meta.url).href, title: ['明星达人合作'], num: '200', unit: '+', width: '245px' },
        { url: new URL(`@/assets/image/detail/sls-particular-imgs-two.png`, import.meta.url).href, title: ['品牌自播GMV'], num: '300w', unit: '+', width: '245px' },
        { url: new URL(`@/assets/image/detail/sls-particular-imgs-three.png`, import.meta.url).href, title: ['爆款视频播放量'], num: '100w', unit: '+', width: '245px' },
        { url: new URL(`@/assets/image/detail/sls-particular-imgs-four.png`, import.meta.url).href, title: ['自营账号涨粉'], num: '5w', unit: '+', width: '245px' },
      ]
    }
  },
  {
    id: 'ssnz',
    headerObj: {
      title: '杉杉男装',
      headerImg: new URL(`@/assets/image/detail/ssnz-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line">
            人、货、场全面匹配，冷启动打爆新品，<span class="hight">店铺入选抖音好店合集</span>
          </div>
        </div>`
    },
    recordObj: {
      type: 'notRecordList-ssnz',
      list: [
        { title: '项目背景', dec: [ '杉杉男装国货品牌分销商希望寻求在平台实现在多渠道账号的生意增长，产品提供为现', '货库存分销模式，通过短视频种草+千川投流，打造内容直播间。' ] },
        { title: '客户期望', dec: [ '产品提供为现货库存分销模式，现有库存产品中通过选品、短视频测款打造单品爆品直', '播间，通过分销解决库存压力同时实现在多渠道平台的生意增长。' ] },
        { title: '解决方案', dec: [ '找到产品精准卖点结合消费者痛点通过短视频完成种草结合千川投放进入直播间完成转化。' ] },
        { title: '整体策略', dec: [ '从短视频品牌种草，数据测款，提取和打造直播间爆品，到直播间团队人货场匹配的极致', '磨合，再到抖店的商城运营多渠道的高效转化，打造品牌抖音经营阵地。' ] },
      ],
      recordStyle: 'background-color: #FFF5F5;'
    },
    particularObj: {
      type: 'imgListTop',
      left: '0',
      list: [
        { url: new URL(`@/assets/image/detail/ssnz-particular-imgs-one.png`, import.meta.url).href, title: ['GMV突破'], num: '600w', unit: '+', width: '245px' },
        { url: new URL(`@/assets/image/detail/ssnz-particular-imgs-two.png`, import.meta.url).href, title: ['进入抖音穿搭榜单'], num: 'TOP100', unit: '', width: '245px' },
        { url: new URL(`@/assets/image/detail/ssnz-particular-imgs-three.png`, import.meta.url).href, title: ['开播三个月', '销售额环比提升'], num: '300', unit: '%', width: '245px' },
      ]
    }
  },
  {
    id: 'hlxgz',
    backgroundClass: 'height-hlxgz',
    headerObj: {
      title: '黄龙溪自媒体运营',
      headerImg: new URL(`@/assets/image/detail/hlxgz-header.png`, import.meta.url).href,
      dec: `<div class="descr-line">
          <div class="line">
            扎根黄龙溪古镇文化，深入探索更具特色的古镇风情，策划专栏内容
          </div>
          <div class="line">
                夯实独具蓉城文化特质的<span class="hight"
                  >「中国天府第一名镇」标签</span
                >
              </div>
        </div>`
    },
    particularObj: {
      type: 'decList',
      title: '案例成果',
      list: [
        { title: '策划专栏内容，打造古镇名片', decStr: `<div class="xc-descr-line">
              <div class="line">
                策划专栏内容包括【匠人系列】、【市井探店】、【黄龙溪新玩法】、【品牌大事记】等
              </div>
              <div class="line">
                系列内容内容包含:水韵、品筑、食遇、古诉等。
              </div>
            </div>`, imgs: [{ url: new URL(`@/assets/image/detail/hlxgz-particular-imgs-one.png`, import.meta.url).href, width: '784px' }] },
        { title: '重塑视觉体系，彰显古镇特色风情', decStr: `<div class="xc-descr-line">
          <div class="line">
            黄龙溪古镇自媒体品牌升级，围绕“漫生活”理念，蕴含文化积淀与产业特色，打造具有
          </div>
          <div class="line">古镇韵味的自媒体视觉体系。</div>
        </div>`, imgs: [{ url: new URL(`@/assets/image/detail/hlxgz-particular-imgs-two.png`, import.meta.url).href, width: '784px' }] },
      ]
    }
  },
]