<template>
  <div class="intelligentTool-cantainer" ref="homeRef">
    <!-- 整合营销专家 -->
    <div class="tool-one-wrap">
      <HeaderWarp navActive="智能工具" pullActive="融智AI客服工具" />

      <div class="inte-market-ape">融智智能客服</div>

      <div class="inte-market-dec one-line">
        专属LLM大模型、专属知识库、自我学习成长
      </div>

      <div class="tool-btn-wrap">
        <div class="btn-wrap free-btn-wrap" @click="clickInfoDia">
          <img
            class="arrow-img arrow-right"
            src="../../assets/image/home/red-right-arrow.png"
            alt=""
          />
          <div class="btn">免费试用15天</div>
          <img
            class="arrow-img arrow-left"
            src="../../assets/image/home/red-left-arrow.png"
            alt=""
          />
        </div>

        <div class="btn-wrap" @click="clickDeConsultant">
          <img
            class="arrow-img arrow-right"
            src="../../assets/image/home/red-right-arrow.png"
            alt=""
          />
          <div class="btn">咨询销售</div>
          <img
            class="arrow-img arrow-left"
            src="../../assets/image/home/red-left-arrow.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- LLM先进大模型 -->
    <div class="tool-two-wrap">
      <div class="tool-text">
        <div class="text en">LLM先进大模型</div>
        <div class="text ch">高智商客服机器人</div>
      </div>

      <div class="chat-wrap" ref="chatRef">
        <div class="chat-box">
          <div class="chart-bg">
            <div
              class="chart-right-img right"
              style="height: 87px; width: 100%"
              :class="showIndex.includes(0) ? 'chart-img-right-block' : ''"
            >
              <img src="../../assets/image/home/customer1.png" alt="" />
            </div>
            <div
              style="height: 47px; width: 100%"
              class="chart-img"
              :class="showIndex.includes(1) ? 'chart-img-left-block' : ''"
            >
              <img src="../../assets/image/home/client1.png" alt="" />
            </div>
            <div
              style="height: 87px; width: 100%; margin-top: 20px"
              class="chart-right-img right"
              :class="showIndex.includes(2) ? 'chart-img-right-block' : ''"
            >
              <img src="../../assets/image/home/customer2.png" alt="" />
            </div>
            <div
              style="height: 47px; width: 100%"
              class="chart-img"
              :class="showIndex.includes(3) ? 'chart-img-left-block' : ''"
            >
              <img src="../../assets/image/home/client2.png" alt="" />
            </div>
          </div>

          <div class="chart-rigth">
            <div class="chart-rigth-item">
              <div class="title">大模型</div>
              <div class="dec">沟通更准确、更友好、更全面</div>
            </div>

            <div class="chart-rigth-item">
              <div class="title">快速集成</div>
              <div class="dec">秒级理解原始文档，智能客服，一键上岗</div>
            </div>

            <div class="chart-rigth-item">
              <div class="title">高效运营</div>
              <div class="dec">大模型协助机器人运营优化，省心省力</div>
            </div>
          </div>
        </div>

        <div class="chart-btn-box">
          <div class="chart-btn-wrap" @click="clickInfoDia">
            <img
              class="chart-arrow-img chart-arrow-right"
              src="../../assets/image/home/black-right-arrow.png"
              alt=""
            />
            <div class="chart-btn">申请试用</div>
            <img
              class="chart-arrow-img chart-arrow-left"
              src="../../assets/image/home/black-left-arrow.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 专注营销客户的智客服首选 -->
    <div class="tool-three-wrap">
      <div class="title">专注营销客户的智客服首选</div>
      <div class="img-wrap">
        <img
          src="../../assets/image/intelligentTool/tool-investment.png"
          alt=""
        />
        <img src="../../assets/image/intelligentTool/tool-law.png" alt="" />
        <img src="../../assets/image/intelligentTool/tool-tech.png" alt="" />
        <img src="../../assets/image/intelligentTool/tool-car.png" alt="" />
      </div>

      <div class="case-wrap">
        <img src="../../assets/image/intelligentTool/tool-case.png" alt="" />
        <div class="case-right">
          <div class="title">夸父炸串</div>
          <div class="dec">利用LLM大模型，帮助企业获取线索，提升线索质量</div>

          <div class="num-wrap">
            <div class="num-item">
              <div class="title">线索数</div>
              <div class="num">+20<span class="unit">%</span></div>
            </div>
            <div class="num-item">
              <div class="title">线索有效率</div>
              <div class="num">+60<span class="unit">%</span></div>
            </div>
            <div class="num-item">
              <div class="title">线索成本</div>
              <div class="num">-60<span class="unit">%</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer ref="footerWrapRef" />

    <!-- 业务咨询弹窗 -->
    <InformationDialog ref="infoDiaRef" />

    <!-- 专属顾问 -->
    <DeConsultant ref="deConsultantRef" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from 'vue'
import HeaderWarp from '/@/components/headerWrap/index.vue'
import Footer from '/@/components/footer/index.vue'
import InformationDialog from '/@/components/informationDialog/index.vue'
import DeConsultant from '/@/components/deConsultant/index.vue'

export default {
  name: 'IntelligentToolPage',
  components: {
    HeaderWarp,
    Footer,
    InformationDialog,
    DeConsultant
  },
  setup() {
    // ref定义
    const homeRef = ref(null)
    const chatRef = ref(null)
    const footerWrapRef = ref(null)
    const infoDiaRef = ref(null)
    const deConsultantRef = ref(null)
    const showIndex = ref([])
    let timer = null
    let isAnimating = false
    // 满足条件只触发一次开关
    let hasTriggeredOnce = false

    const handleScroll = () => {
      // 聊天动效逻辑
      const top = chatRef.value.getBoundingClientRect().top
      if (top <= 200 && top >= -760 && !hasTriggeredOnce) {
        hasTriggeredOnce = true
        if (!isAnimating) {
          isAnimating = true
          timer = setInterval(() => {
            if (showIndex.value.length < 4) {
              showIndex.value.push(showIndex.value.length)
            } else {
              clearInterval(timer)
              timer = null
              isAnimating = false
            }
          }, 900)
        }
      } else if (hasTriggeredOnce && (top > 200 || top < -760)) {
        if (timer) {
          clearInterval(timer)
          timer = null
          isAnimating = false
        }
        showIndex.value = []
        hasTriggeredOnce = false
      }

      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }

    // 打开业务咨询弹窗
    const clickInfoDia = () => {
      infoDiaRef.value.open()
    }

    // 打开专属顾问
    const clickDeConsultant = () => {
      deConsultantRef.value.open()
    }

    onMounted(() => {
      homeRef.value.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
      if (timer) {
        clearInterval(timer)
      }
    })

    return {
      homeRef,
      chatRef,
      footerWrapRef,
      showIndex,
      clickInfoDia,
      infoDiaRef,
      clickDeConsultant,
      deConsultantRef
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>